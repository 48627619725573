<template>
  <div class="container pb-3">
    <div class="mt-4" v-if="loading">
      <loader></loader>
    </div>
    <div v-else>
      <div class="pulse-checks row pt-1 no-margin">
        <div class="col-sm-12 col-lg-8 offset-lg-2 mt-4">
          <h4 class="title" v-if="data" v-html="$options.filters.replaceTags(data.title)"></h4>
          <h4 class="title" v-html="$options.filters.replaceTags(feedback.title)" v-else>
            <small>{{ 'quiz.results' | translate }}</small>
          </h4>
        </div>
      </div>
      <form @submit.prevent="next" v-if="data">
        <div class="row mt-3">
          <div class="col-sm-12 col-lg-8 offset-lg-2">
            <div v-if="!loading && data && !data.items.length">
              <h6>{{ 'pulseCheck.noQuestions' | translate }}</h6>
            </div>
            <div class="mt-1 p-1 card" v-else>
              <div v-if="current" class="mt-2 card-header bg-white">
                <h5 :style="{ color: style.quizTitleColor }">
                  <div v-html="$options.filters.replaceTags(current.question)"></div>
                </h5>
              </div>
              <div class="card-body">
                <div class="mt-2 mb-4" v-if="current.movieUrl">
                  <div class="video-module embed-responsive embed-responsive-16by9" v-html="current.movieUrl"></div>
                </div>
                <div v-for="answer in current.answers" :key="answer.id">
                  <div class="form-check mb-2">
                    <input
                      v-if="currentType === 'radio'"
                      :id="'question-' + answer.id"
                      type="radio"
                      :name="'question-' + answer.id"
                      v-model.number="current.answer"
                      :value="answer.id"
                      class="form-check-input"
                      :required="!current.answer"
                    />
                    <input
                      v-if="currentType === 'checkbox'"
                      :id="'question-' + answer.id"
                      type="checkbox"
                      :name="'question-' + answer.id"
                      :value="answer.id"
                      class="form-check-input"
                      :required="!current.answer"
                      @change="selectAnswer(answer.id)"
                    />
                    <label
                      class="form-check-label pointer"
                      :for="'question-' + answer.id"
                      v-html="$options.filters.replaceTags(answer.answer)"
                    ></label>
                  </div>
                </div>
              </div>
              <div v-if="showSteps" class="card-footer bg-white mb-1">
                <el-steps :active="activeStep" direction="horizontal">
                  <el-step
                    @onStepClick="onStepClick(index)"
                    v-for="(item, index) in data.items"
                    :key="item.id"
                    :class="{ pointer: index <= stepsFinished, 'not-allowed': index > stepsFinished }"
                    :status="index < stepsFinished ? 'finish' : index === stepsFinished ? 'process' : 'wait'"
                  ></el-step>
                </el-steps>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 mb-4">
          <div class="col-sm-12 col-lg-8 offset-lg-2">
            <div class="float-left">
              <default-button @click="cancel" :disabled="loading" v-if="showGeneralButtons">
                <i class="material-icons float-left mr-1">cancel</i>
                {{ 'general.cancel' | translate }}
              </default-button>
            </div>
            <div class="float-right">
              <primary-button class="mr-2" @click="cancel" :disabled="loading" v-if="showReturnButton">
                <i class="material-icons float-left mr-1">arrow_back</i>
                {{ 'general.returnToDashboard' | translate }}
              </primary-button>
              <secondary-button
                class="mr-2"
                @click="previous"
                :disabled="loading"
                v-if="showGeneralButtons && activeStep > 0"
              >
                <i class="material-icons float-left mr-1">arrow_back</i>
                {{ 'quiz.previous' | translate }}
              </secondary-button>
              <primary-button
                type="submit"
                :disabled="loading || !current.answer"
                v-if="showGeneralButtons && activeStep === data.items.length - 1"
              >
                <i class="material-icons float-left mr-1">send</i>
                {{ 'general.send' | translate }}
              </primary-button>
              <primary-button
                class="ml-2"
                type="submit"
                :disabled="loading || !current.answer"
                v-if="showGeneralButtons && activeStep < data.items.length - 1"
              >
                <i class="material-icons float-left mr-1">arrow_forward</i>
                {{ 'quiz.next' | translate }}
              </primary-button>
            </div>
          </div>
        </div>
      </form>
      <div class="row" v-if="feedback">
        <div class="col-sm-12 col-lg-8 offset-lg-2">
          <div class="card mt-4" v-for="item in feedback.items" :key="item.id">
            <div class="card-header bold" v-html="$options.filters.replaceTags(item.question)"></div>
            <div class="card-body">
              <div class="mt-2 mb-4" v-if="item.movieUrl">
                <div class="video-module embed-responsive embed-responsive-16by9" v-html="item.movieUrl"></div>
              </div>
              <p v-if="areAnswersCorrect(item.answers)">
                <i class="material-icons float-left mr-2 success-icon">check_circle</i>
                <span v-for="(answer, index) in chosenAnswers(item.answers)"
                  ><strong>{{ answer.answer }}</strong
                  ><span v-if="index !== correctAnswers(item.answers).length - 1">, </span></span
                >
              </p>
              <p v-if="!areAnswersCorrect(item.answers)">
                <i
                  v-if="correctAnswers(item.answers).length === 1 || areAllAnswersIncorrect(item.answers)"
                  class="material-icons float-left mr-2 wrong-icon-single"
                  >do_not_disturb_on</i
                >
                <i v-else class="material-icons float-left mr-2 wrong-icon">warning</i>
                {{ 'quiz.youHaveAnswered' | translate }}
                <span v-for="(answer, index) in chosenAnswers(item.answers)">
                  <span
                    ><strong>{{ answer.answer }}</strong
                    ><span v-if="index !== chosenAnswers(item.answers).length - 1">, </span></span
                  >
                </span>
                <span v-if="correctAnswers(item.answers).length > 1"
                  >{{ 'quiz.butthecorrectanswerswere' | translate }}
                </span>
                <span v-else>{{ 'quiz.butthecorrectanswerwas' | translate }} </span>
                <span v-for="(answer, index) in correctAnswers(item.answers)">
                  <span
                    ><strong>{{ answer.answer }}</strong
                    ><span v-if="index !== correctAnswers(item.answers).length - 1">, </span></span
                  >
                </span>
              </p>
              <div v-if="item.feedback" v-html="$options.filters.replaceTags(item.feedback)"></div>
            </div>
          </div>
        </div>
        <div class="col-sm-12 col-lg-8 offset-lg-2 mt-4 mb-4">
          <div class="float-right">
            <secondary-button class="mr-3" @click="retake" :disabled="loading" v-if="feedback.allowNewAttemptQuiz">
              <i class="material-icons float-left mr-1">refresh</i>
              {{ 'quiz.takeQuizAgain' | translate }}
            </secondary-button>
            <primary-button @click="backToDashboard" :disabled="loading">
              <i class="material-icons float-left mr-1">arrow_back</i>
              {{ 'general.returnToDashboard' | translate }}
            </primary-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import each from 'lodash/each';
import filter from 'lodash/filter';
import planhatMixin from '../../mixins/planhat';

import { Steps } from 'element-ui';
import Step from '../../components/custom/Step';

export default {
  mixins: [planhatMixin],
  components: {
    elStep: Step,
    elSteps: Steps
  },
  created() {
    this.$http.get(`dashboard/testyourselves/${this.$route.params.overview}/items/${this.$route.params.id}`).then(
      (response) => {
        each(response.data.items, (i) => {
          if (i.movieUrl) {
            i.movieUrl = i.movieUrl
              .replace(/(width=")\d+("\W+height=")\d+/, '$1100%$2100%')
              .replace('src="//', 'src="https://')
              .replace('autoplay=1', 'autoplay=0')
              .replace('<iframe', '<iframe class="embed-responsive-item"');
          }
        });
        if (response.data.hasQuizMade) {
          this.quizContent = response.data;
          return this.getFeedback();
        }
        this.data = response.data;
        this.loading = false;
      },
      (err) => {
        this.$notify({
          type: 'error',
          message: this.$t('notifications.error'),
          position: 'bottom-right'
        });
        this.loading = false;
        // go back by one record, the same as history.back()
        this.$router.go(-1);
      }
    );
  },
  computed: {
    ...mapGetters('settings', ['style']),
    current() {
      return this.data && this.data.items && this.data.items.length ? this.data.items[this.activeStep] : null;
    },
    currentType() {
      return this.current
        ? filter(this.current.answers, (answer) => answer.isCorrect).length > 1
          ? 'checkbox'
          : 'radio'
        : 'radio';
    },
    hasSelectedAnswer() {
      return this.current ? filter(this.current.answers, (answer) => answer.selectedAnswer).length : false;
    },
    showReturnButton() {
      return !this.loading && this.data && !this.retakingQuiz && (this.data.hasQuizMade || !this.data.items.length);
    },
    showGeneralButtons() {
      return !this.loading && this.data && (this.retakingQuiz || !this.data.hasQuizMade) && this.data.items.length;
    },
    showSteps() {
      return this.data && this.data.items && this.data.items.length > 1;
    }
  },
  data() {
    return {
      activeStep: 0,
      data: null,
      feedback: null,
      loading: true,
      retakingQuiz: false,
      quizContent: null,
      stepsFinished: 0
    };
  },
  methods: {
    // replaceTags(value) {
    //   return value.replace(/&lt;/g,'<').replace(/&gt;/g,'>');
    // },
    backToDashboard() {
      this.$router.push('/');
    },
    cancel() {
      this.$router.go(-1);
    },
    getFeedback() {
      this.$http
        .get(`dashboard/testyourselves/${this.$route.params.overview}/items/${this.$route.params.id}/feedback`)
        .then(
          (response) => {
            each(response.data.items, (i) => {
              if (i.movieUrl) {
                i.movieUrl = i.movieUrl
                  .replace(/(width=")\d+("\W+height=")\d+/, '$1100%$2100%')
                  .replace('src="//', 'src="https://')
                  .replace('autoplay=1', 'autoplay=0')
                  .replace('<iframe', '<iframe class="embed-responsive-item"');
              }
            });

            // Get all correct answers if multiple possible
            response.data.items = response.data.items.map((feedback) => {
              feedback.correctAnswer = feedback.answers
                .filter((answer) => answer.isCorrect)
                .map((answer) => answer.answer);
              return feedback;
            });

            // Fix multiple answer correct answer
            response.data.items = response.data.items.map((feedback) => {
              if (feedback.isCorrect || feedback.correctAnswer.indexOf(feedback.ownAnswer) > -1) {
                feedback.isCorrect = true;
              }
              return feedback;
            });

            this.feedback = response.data;
            this.loading = false;
          },
          (err) => {
            this.$notify({
              type: 'error',
              message: this.$t('notifications.error'),
              position: 'bottom-right'
            });
            this.loading = false;
            this.$router.go(-1); // go back by one record, the same as history.back()
          }
        );
    },
    next() {
      if (this.showGeneralButtons && this.activeStep === this.data.items.length - 1) {
        return this.submit();
      }
      this.activeStep++;
      this.stepsFinished = Math.max(this.stepsFinished, this.activeStep);
    },
    onStepClick(index) {
      if (index > this.stepsFinished) return;
      this.activeStep = index;
    },
    previous() {
      this.activeStep--;
    },
    retake() {
      this.retakingQuiz = true;
      const answers = [];
      this.feedback.items.forEach((item) => {
        item.answers.forEach((answer) => {
          if (answer.isChosen) answers.push(answer.answerId);
        });
      });

      const data = {
        QuizId: this.$route.params.overview,
        Id: this.$route.params.id,
        Items: answers
      };

      this.loading = true;

      this.$http
        .delete(`dashboard/testyourselves/${this.$route.params.overview}/items/${this.$route.params.id}/answers`, data)
        .then(
          () => {
            this.feedback = null;
            this.data = this.quizContent;
            this.loading = false;
          },
          (err) => {
            this.loading = false;
            this.$notify({
              type: 'error',
              message: this.$t('notifications.error'),
              position: 'bottom-right'
            });
          }
        );
    },
    submit() {
      this.loading = true;
      let answers = [];
      this.data.items.forEach((item) => {
        // Multi answer checkboxes
        if (Array.isArray(item.answer)) {
          answers.push(...item.answer);
        } else {
          // Single answer radio
          answers.push(item.answer);
        }
      });

      const data = {
        QuizId: this.$route.params.overview,
        Id: this.$route.params.id,
        Items: answers
      };

      this.$http
        .post(`dashboard/testyourselves/${this.$route.params.overview}/items/${this.$route.params.id}/answers`, data)
        .then(
          () => {
            this.$notify({
              type: 'success',
              message: this.$t('notifications.quizDataSaved'),
              position: 'bottom-right'
            });

            //Planhat quiz data submitted, description is optional and can be changed to relevant info
            this.track('tal_quiz_submission_completed');
            setTimeout(() => {
              window.location.reload();
            }, 300);
          },
          (err) => {
            if (err !== 'demo') {
              this.$notify({
                type: 'error',
                message: this.$t('notifications.error'),
                position: 'bottom-right'
              });
            }
            this.loading = false;
          }
        );
    },
    selectAnswer(answerId) {
      // Note only used in case multiple answers possible
      if (!this.current.answer) {
        Vue.set(this.current, 'answer', []);
      }

      const answerIndex = this.current.answer.indexOf(answerId);
      if (answerIndex > -1) {
        this.current.answer.splice(answerIndex);
      } else {
        this.current.answer.push(answerId);
      }

      if (this.current.answer && this.current.answer.length === 0) {
        Vue.delete(this.current, 'answer');
      }
    },
    toggleAnswer(answerId) {
      if (this.current.answer === answerId) {
        Vue.delete(this.current, 'answer');
      } else {
        Vue.set(this.current, 'answer', answerId);
      }
    },
    chosenAnswers(answers) {
      return answers.filter((answer) => answer.isChosen);
    },
    correctAnswers(answers) {
      return answers.filter((answer) => answer.isCorrect);
    },
    areAllAnswersIncorrect(answers) {
      let isIncorrect = true;
      answers.forEach((answer) => {
        if (answer.isChosen && answer.isCorrect) {
          isIncorrect = false;
        }
      });
      return isIncorrect;
    },
    areAnswersCorrect(answers) {
      let isCorrect = true;
      answers.forEach((answer) => {
        if (answer.isCorrect !== answer.isChosen) {
          isCorrect = false;
        }
      });
      return isCorrect;
    }
  }
};
</script>

<style lang="scss" scoped>
.play-icon {
  font-size: 6em;
  left: 221px;
  top: 170px;
  position: absolute;
}

.wrong-icon {
  color: orange;
}

.wrong-icon-single {
  color: red;
}

.success-icon {
  color: green;
}

.form-check {
  display: flex;
  align-items: center;
}

.form-check input[type='radio'] {
  margin-top: 0;
}

.material-icons {
  font-size: var(--title-size);
}
@media (max-width: 767px) {
  .form-check-label {
    padding: 15px 15px 15px 5px;
    display: block;
  }

  .form-check {
    border: 1px solid #eeeeee;
    margin-bottom: -1px;
    padding-left: 2.25rem;
  }
}

.title {
  font-size: var(--title-size);
}

h5,
h6 {
  font-size: var(--subtitle-size);
}
</style>
